import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

const steps = {
    SPLASH: "splash",
    CRM: "crm",
    INSTRUCTIONS: "instructions",
    SWIPE: "swipe",
    DESTINATIION: "destination",
    MAP: "map"
};


app.config.globalProperties.$steps = steps;
app.config.globalProperties.$step = steps.SWIPE;

app.mount('#app')
