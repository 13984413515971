<template>
  <div class="touch-wrapper" :class="{ 'no-touch': step == 'swipe' }">
    <Splash v-if="step == 'splash'" @setName="onSetName" @changeStep="onChangeStep" />
    <Register v-if="step == 'register'" @changeStep="onChangeStep" />
    <CRM v-if="step == 'crm'" @changeStep="onChangeStep" @setName="onSetName" />
    <Instructions v-if="step == 'instructions'" @changeStep="onChangeStep" :firstName="firstName" />
    <Swipe v-if="step == 'swipe'" @changeStep="onChangeStep" @setPrefs="onSetPrefs" />
    <Destination v-if="step == 'destination'" @changeStep="onChangeStep" @setDestination="onSetDestination" :name="firstName" :prefs="prefs" />
    <MapPreview v-if="step == 'map'" @changeStep="onChangeStep" :destination="destination" :firstName="firstName" :isKiosk="isKiosk" />
    <Footer v-if="step != 'swipe'" />
  </div>
</template>

<script>
import Splash from './components/Splash.vue'
import Register from './components/Register.vue'
import CRM from './components/CRM.vue'
import Instructions from './components/Instructions.vue'
import Swipe from './components/Swipe.vue'
import Destination from './components/Destination.vue'
import MapPreview from './components/MapPreview.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Splash,
    Register,
    CRM,
    Instructions,
    Swipe,
    Destination,
    MapPreview,
    Footer
  },
  data() {
    return {
      step: "splash",
      firstName: null,
      destination: null,
      prefs: null,
      isKiosk: false
    }
  },
  mounted(){
    if(window.location.search.indexOf('debugLocation') > -1){
      console.log(window.location.search)
      let uri = window.location.search.substring(1); 
      let params = new URLSearchParams(uri);
      console.log(params.get("debugLocation"));
      this.step = 'destination'
    }
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      // We execute the same script as before
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    console.log(window.location.hash);
    console.log(window.location.hash.indexOf('kiosk'));
    if(window.location.hash.indexOf('kiosk') > 0){
      this.isKiosk = true;
    }
  },
  methods: {
    onChangeStep(value){
      this.step = value;
      if(this.step == 'map'){
        //document.body.style.backgroundImage = "url('/../destinationMaps/" + this.destination.city.toLowerCase().replace(' ','-') + ".jpg')";
        //document.body.style.backgroundImage = "url('/../destinationMaps/x.jpg')";
      }
      if(this.step == 'splash'){
        document.body.style.backgroundImage = "";
        this.firstName = null;
        this.destination = null;
        this.prefs = null;
      }
      // if(this.step == 'swipe'){
      //   document.body.getElementById('app').style.touchAction == "none";
      // }else{
      //   document.body.getElementById('app').style.touchAction == "auto";
      // }
      if(this.step == 'instructions'){
        document.body.style.backgroundImage = "";
      }
    },
    onSetName(value){
      if(value == "Guest"){
        window.fathom.trackGoal('RADRFOJT', 0);
      }else{
        window.fathom.trackGoal('QANCW32A', 0);
      }
      this.firstName = value;
      console.log("App: new firstName: " + this.firstName);
    },
    onSetDestination(value){
      this.destination = value;
      document.body.style.backgroundImage = "url('/../destinations/" + this.destination.city.toLowerCase().replace(' ','-') + ".jpg')";
    },
    onSetPrefs(value){
      this.prefs = value;
      console.log("App: new prefs: ");
      console.log(this.prefs);
    }
  }
}
</script>

<style>
@font-face {
  font-family: "Aldine-721-Light";
  src: url('./assets/font/tt0804m.eot');
  src: url('./assets/font/tt0804m.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/tt0804m.woff') format('woff'),
    url('./assets/font/tt0804m_.ttf') format('truetype');
}

@font-face {
  font-family: "Aldine-721-LightItalic";
  src: url('./assets/font/tt0805m.eot');
  src: url('./assets/font/tt0805m.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/tt0805m.woff') format('woff'),
    url('./assets/font/tt0805m_.ttf') format('truetype');
}

@font-face {
  font-family: "Swiss-721-Light";
  src: url('./assets/font/Swiss721BT-Light.eot');
  src: url('./assets/font/Swiss721BT-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Swiss721BT-Light.woff') format('woff'),
    url('./assets/font/Swiss721BT-Light.ttf') format('truetype'),
    url('./assets/font/Swiss721BT-Light.svg#Swiss-721-Light') format('svg');
}

@font-face {
  font-family: "Swiss-721-Regular";
  src: url('./assets/font/Swiss721BT-Regular.eot');
  src: url('./assets/font/Swiss721BT-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Swiss721BT-Regular.woff') format('woff'),
    url('./assets/font/Swiss721BT-Regular.ttf') format('truetype'),
    url('./assets/font/Swiss721BT-Regular.svg#Swiss-721-Regular') format('svg');
}

@font-face {
  font-family: "Swiss-721-Medium";
  src: url('./assets/font/Swiss721BT-Medium.eot');
  src: url('./assets/font/Swiss721BT-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Swiss721BT-Medium.woff') format('woff'),
    url('./assets/font/Swiss721BT-Medium.ttf') format('truetype'),
    url('./assets/font/Swiss721BT-Medium.svg#Swiss-721-Medium') format('svg');
}

@font-face {
  font-family: "Swiss-721-Bold";
  src: url('./assets/font/Swiss721BT-Bold.eot');
  src: url('./assets/font/Swiss721BT-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Swiss721BT-Bold.woff') format('woff'),
    url('./assets/font/Swiss721BT-Bold.ttf') format('truetype'),
    url('./assets/font/Swiss721BT-Bold.svg#Swiss-721-Bold') format('svg');
}

@font-face {
  font-family: "Swiss-721-BoldCondensed";
  src: url('./assets/font/Swiss721BT-BoldCondensed.eot');
  src: url('./assets/font/Swiss721BT-BoldCondensed.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Swiss721BT-BoldCondensed.woff') format('woff'),
    url('./assets/font/Swiss721BT-BoldCondensed.ttf') format('truetype');
}

body{
  overflow-x: hidden;
  background-size: cover;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-position: bottom center;
  background-color: #F9F6F4;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.no-touch{
  touch-action: none;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.mx-auto{
  margin: auto;
}

button{
  cursor: pointer;
}

.button--grey{
  background-color: #707070;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  line-height: 22px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.button--black{
  font-family: "Swiss-721-Bold";
  width: 160px;
  height: 58px;
  background-color: #1c1c1c;
  color: white;
  border-radius: 29px;
  font-size: 16px;
  text-transform: uppercase;
}

.button--black-outlined{
  font-family: "Swiss-721-Bold";
  width: 160px;
  height: 58px;
  background-color: #1c1c1c;
  color: white;
  border-radius: 29px;
  font-size: 16px;
  text-transform: uppercase;
  border: 2px solid white;
}

.button--white{
  font-family: "Swiss-721-Bold";
  width: 160px;
  height: 58px;
  background-color: white;
  color: #1c1c1c;
  border: 2px solid #1c1c1c;
  border-radius: 29px;
  font-size: 16px;
  text-transform: uppercase;
}

.button--orange{
  font-family: "Swiss-721-Bold";
  width: 160px;
  height: 58px;
  background-color: #ff9662;
  color: #010101;
  border-radius: 29px;
  font-size: 16px;
  text-transform: uppercase;
}

.bonvoy-link{
  font-family: "Swiss-721-Regular";
  text-decoration: underline;
  color: #B84c16;
  font-size: 16px;
}

.bonvoy-frame--external{
  margin-left: 44px;
  margin-right: 44px;
  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0 0 14px 0 rgba(0,0,0,0.14);
}

.bonvoy-frame--internal{
  margin: 0;
  border-radius: 30px;
  min-height: calc(100vh - 120px);
  min-height: calc(var(--vh, 1vh) * 100 - 120px);
}

.filter--black{
  filter: brightness(0%);
}

@media (max-width: 640px){
  .bonvoy-frame--external{
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>
