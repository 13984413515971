<template>
    <div class="destination" v-if="destination">
        <div class="destination__accent">
            <img class="destination__accent-pin" src="../assets/img/Location.svg" />
            {{destination.displayCity}}<hr><br>
            {{destination.country}}
        </div>
        <div class="bonvoy-frame--external">
            <div class="bonvoy-frame--internal">
                <img class="destination__mobile-img" :src="'../destinations/' + destination.city.toLowerCase().replace(' ', '-') + '.jpg'" style="padding: 16px">
                <img class="destination__logo" src="../assets/img/bonvoy-logo.png" @click="$emit('changeStep','splash')">
                <h1 class="destination__h1">We've Found Your<br/>Perfect Match!</h1>
                <h2 class="destination__h2">The travel destination of your dreams is</h2>
                <h3 class="destination__h3 mx-auto">
                  <img class="filter--black destination__h3-pin" src="../assets/img/Location.svg" />
                  {{destination.country ? destination.displayCity + ', ' + destination.country : destination.displayCity}}
                </h3>
                <p class="destination__p mx-auto">{{calcPrefString()}}</p>
                <button class="button--black" @click="$emit('changeStep', 'map')">Next</button>
                &nbsp;
                <button class="button--white" @click="$emit('changeStep', 'instructions')">Play Again</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Destination',
  emits: ["changeStep", "setDestination"],
  props: {
      name: {
          type: String,
          default: null
      },
      prefs: {
          type: Object,
          default: null
      },
  },
  data() {
    return {
        destination: null
    }  
  },
  mounted(){
        this.destination = this.calcDestination();
        this.$emit('setDestination', this.destination);
  },
  methods: {
      calcPrefString: function(){
           if(window.location.search.indexOf('debugLocation') > -1){
            return "This location was hand-selected by you!"
          }
          var prefStringParts = [];
          if(this.prefs.hot != 0){
              prefStringParts.push(this.prefs.hot > 0 ? 'a hot climate' : 'a cold climate');
          }
          if(this.prefs.adventurous != 0){
              prefStringParts.push(this.prefs.adventurous > 0 ? 'adventurous activities' : 'relaxing activities');
          }
          if(this.prefs.beach != 0){
              prefStringParts.push(this.prefs.beach > 0 ? 'being at the beach' : 'being in the mountains');
          }
          if(this.prefs.action != 0){
              prefStringParts.push(this.prefs.action > 0 ? 'finding the action' : 'experiencing culture');
          }
          if(this.prefs.ancient != 0){
              prefStringParts.push(this.prefs.ancient > 0 ? 'exploring ancient sites' : 'exploring modern marvels');
          }
          if(prefStringParts.length > 2){
              prefStringParts[prefStringParts.length - 1] = 'and ' + prefStringParts[prefStringParts.length - 1];
              return (this.name =='Guest' ? 'We can tell you prefer ' : this.name + ', we can tell you prefer ') + prefStringParts.join(', ') + '.';
          }else{
              return (this.name =='Guest' ? 'We can tell you prefer ' : this.name + ', we can tell you prefer ') + prefStringParts.join(' and ') + '.';
          }
      },
      calcDestination: function(){
          var randVal = Math.random();
          var cityLookupCode = '';
          if(window.location.search.indexOf('debugLocation') > -1){
            console.log(window.location.search)
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            console.log(params.get("debugLocation"));
            cityLookupCode = params.get("debugLocation") + '';
          }else{
            if(this.prefs.adventurous == 0){
                cityLookupCode += Math.round(Math.random());
            }else{
                cityLookupCode += (this.prefs.adventurous > 0 ? '0' : '1');
            }
            if(this.prefs.beach == 0){
                cityLookupCode += Math.round(Math.random());
            }else{
                cityLookupCode += (this.prefs.beach > 0 ? '0' : '1');
            }
            if(this.prefs.action == 0){
                cityLookupCode += Math.round(Math.random());
            }else{
                cityLookupCode += (this.prefs.action > 0 ? '0' : '1');
            }
            if(this.prefs.city == 0){
                cityLookupCode += Math.round(Math.random());
            }else{
                cityLookupCode += (this.prefs.city > 0 ? '0' : '1');
            }
            if(this.prefs.ancient == 0){
                cityLookupCode += Math.round(Math.random());
            }else{
                cityLookupCode += (this.prefs.ancient > 0 ? '0' : '1');
            }
            if(this.prefs.hot == 0){
                cityLookupCode += Math.round(Math.random());
            }else{
                cityLookupCode += (this.prefs.hot > 0 ? '0' : '1');
            }
          }
          /*
            code:
            adventurous vs relaxing 1
            beach vs mountains 0
            action vs culture 1
            city vs country 1
            ancient vs modern 0
            hot vs cold 0
          */
          switch (cityLookupCode){
            case '000000': return {
                city: 'Nice',
                displayCity: 'Nice',
                country: 'France',
                hotel: 'Le Méridien Nice',
                hotelLine1: 'Le Méridien',
                hotelLine2: 'Nice'
            };
            case '000001': return {
                city: 'Barcelona',
                displayCity: 'Barcelona',
                country: 'Spain',
                hotel: 'The Barcelona EDITION',
                hotelLine1: 'The',
                hotelLine2: 'Barcelona EDITION'
            };
            case '000010': return {
                city: 'St. James',
                displayCity: 'St. James',
                country: 'Barbados',
                hotel: 'Colony Club by Elegant Hotels',
                hotelLine1: 'Colony Club',
                hotelLine2: 'by Elegant Hotels'
            };
            case '000011': return {
                city: 'San Francisco',
                displayCity: 'San Francisco',
                country: 'USA',
                hotel: 'Palace Hotel',
                hotelLine1: 'Palace Hotel, a Luxury',
                hotelLine2: 'Collection Hotel, San Francisco'
            };
            case '000100': return {
                city: 'Calangute',
                displayCity: 'Calangute',
                country: 'Goa',
                hotel: 'Le Méridien Goa',
                hotelLine1: 'Le Méridien Goa,',
                hotelLine2: 'Calangute'
            };
            
            case '000101': return {
                city: 'Lima',
                displayCity: 'Lima',
                country: 'Peru',
                hotel: 'Courtyard Lima Miraflores',
                hotelLine1: 'Courtyard Lima',
                hotelLine2: 'Miraflores'
            };
            case '000110': 
                if(randVal < .33){
                    return {
                        city: 'Corfu',
                        displayCity: 'Corfu',
                        country: 'Greece',
                        hotel: 'Domes of Corfu',
                        hotelLine1: 'Domes of Corfu,',
                        hotelLine2: 'Autograph Collection'
                    };
                }else if(randVal < .66){
                    return {
                        city: 'Lake Buena Vista',
                        displayCity: 'Lake Buena Vista',
                        country: 'USA',
                        hotel: 'Walt Disney World Swan',
                        hotelLine1: 'Walt Disney World',
                        hotelLine2: 'Swan'
                    };
                }else{
                    return {
                        city: 'Sharm El Sheikh',
                        displayCity: 'Sharm El Sheikh',
                        country: 'Egypt',
                        hotel: 'Renaissance Sharm El Sheikh',
                        hotelLine1: 'Renaissance Sharm El Sheikh',
                        hotelLine2: 'Golden View Beach Resort'
                    };
                }
            case '000111': return {
                city: 'Istanbul',
                displayCity: 'Istanbul',
                country: 'Turkey',
                hotel: 'Sheraton Istanbul Levent',
                hotelLine1: 'Sheraton Istanbul',
                hotelLine2: 'Levent'
            };
            case '001000': return {
                city: 'Buenos Aires',
                displayCity: 'Buenos Aires',
                country: 'Argentina',
                hotel: 'Park Tower Buenos Aires',
                hotelLine1: 'Park Tower, a Luxury Collection',
                hotelLine2: 'Hotel, Buenos Aires'
            };
            case '001001':
                if(randVal < .5){
                    return {
                        city: 'Amsterdam',
                        displayCity: 'Amsterdam',
                        country: 'Netherlands',
                        hotel: 'W Amsterdam',
                        hotelLine1: 'W',
                        hotelLine2: 'Amsterdam'
                    };
                }else{
                    return {
                        city: 'Venice',
                        displayCity: 'Venice',
                        country: 'Italy',
                        hotel: 'JW Marriott Venice',
                        hotelLine1: 'JW Marriott',
                        hotelLine2: 'Venice Resort & Spa'
                    };
                }
            case '001010':
                 if(randVal < .33){
                    return {
                        city: 'Rome',
                        displayCity: 'Rome',
                        country: 'Italy',
                        hotel: 'W Rome',
                        hotelLine1: 'W',
                        hotelLine2: 'Rome'
                    };
                }else if (randVal < .66){
                    return {
                        city: 'Auckland',
                        displayCity: 'Auckland',
                        country: 'New Zealand',
                        hotel: 'Four Points by Sheraton Auckland',
                        hotelLine1: 'Four Points',
                        hotelLine2: 'by Sheraton Auckland'
                    };
                }else{
                    return {
                        city: 'Kuwait City',
                        displayCity: 'Kuwait City',
                        country: 'Kuwait',
                        hotel: 'Courtyard Kuwait City',
                        hotelLine1: 'Courtyard',
                        hotelLine2: 'Kuwait City'
                    };
                }
            case '001011': 
                if(randVal < .5){
                    return {
                        city: 'Shanghai',
                        displayCity: 'Shanghai',
                        country: 'China',
                        hotel: 'JW Marriott Hotel Shanghai',
                        hotelLine1: 'JW Marriott Hotel',
                        hotelLine2: 'Shanghai at Tomorrow Square'
                    }   
                }else{
                    return {
                        city: 'Florence',
                        displayCity: 'Florence',
                        country: 'Italy',
                        hotel: 'The Westin Excelsior, Florence',
                        hotelLine1: 'The Westin Excelsior,',
                        hotelLine2: 'Florence'
                    };
                }
            case '001100': 
                if(randVal < .5){
                    return {
                        city: 'Kochi',
                        displayCity: 'Kochi',
                        country: 'India',
                        hotel: 'Four Points by Sheraton Kochi Infopark',
                        hotelLine1: 'Four Points by Sheraton',
                        hotelLine2: 'Kochi Infopark'
                    }
                }else{
                    return {
                        city: 'Rio de Janeiro',
                        displayCity: 'Rio de Janeiro',
                        country: 'Brazil',
                        hotel: 'JW Marriott Hotel Rio de Janeiro',
                        hotelLine1: 'JW Marriott Hotel',
                        hotelLine2: 'Rio De Janeiro'
                    }
                }
            case '001101':
                return{ 
                    city: 'Ibiza',
                    displayCity: 'Ibiza',
                    country: 'Spain',
                    hotel: 'W Ibiza',
                    hotelLine1: 'W',
                    hotelLine2: 'Ibiza'
                };
            case '001110': return {
                city: 'Langkawi',
                displayCity: 'Langkawi',
                country: 'Malaysia',
                hotel: 'The Westin Langkawi Resort & Spa',
                hotelLine1: 'The Westin Langkawi',
                hotelLine2: 'Resort & Spa'
            };
            case '001111': return {
                city: 'Seoul',
                displayCity: 'Seoul',
                country: 'Korea',
                hotel: 'Courtyard Seoul Times Square',
                hotelLine1: 'Courtyard Seoul',
                hotelLine2: 'Times Square'
            };
            case '010000': return {
                city: 'Santos',
                displayCity: 'Santos',
                country: 'Brazil',
                hotel: 'Sheraton Santos Hotel',
                hotelLine1: 'Sheraton',
                hotelLine2: 'Santos Hotel'
            };
            case '010001': return {
                city: 'Washington, D.C.',
                displayCity: 'Washington, D.C.',
                country: 'USA',
                hotel: 'Renaissance Washington, DC Downtown Hotel',
                hotelLine1: 'Renaissance Washington, DC',
                hotelLine2: 'Downtown Hotel'
            };
            case '010010': 
                if(randVal < .33){
                    return {
                        city: "Nashville",
                        displayCity: 'Nashville',
                        country: 'USA',
                        hotel: 'Renaissance Nashville Hotel',
                        hotelLine1: 'Renaissance',
                        hotelLine2: 'Nashville Hotel'
                    };
                }else if(randVal < .66){
                    return {
                        city: "Nashville2",
                        displayCity: 'Nashville',
                        country: 'USA',
                        hotel: 'W Nashville Hotel',
                        hotelLine1: 'W',
                        hotelLine2: 'Nashville Hotel'
                    };
                }else{
                    return {
                        city: 'Las Vegas',
                        displayCity: 'Las Vegas',
                        country: 'Nevada',
                        hotel: 'JW Marriott Las Vegas Resort & Spa',
                        hotelLine1: 'JW Marriott Las Vegas',
                        hotelLine2: 'Resort & Spa'
                    };
                }
            case '010011': return {
                city: 'New York',
                displayCity: 'New York City',
                country: 'USA',
                hotel: 'The Ritz-Carlton Central Park, New York',
                hotelLine1: 'The Ritz-Carlton',
                hotelLine2: 'New York, Central Park'
            };
            case '010100':
                if(randVal < .5){
                    return {
                        city: 'Dubai',
                        displayCity: 'Dubai',
                        country: 'United Arab Emirates',
                        hotel: 'Sheraton Grand Hotel',
                        hotelLine1: 'Sheraton Grand Hotel,',
                        hotelLine2: 'Dubai'
                    };
                }else{
                    return {
                        city: 'Dubai2',
                        displayCity: 'Dubai',
                        country: 'United Arab Emirates',
                        hotel: 'Al Maha, a Luxury Collection Desert Resort & Spa',
                        hotelLine1: 'Al Maha, a Luxury Collection',
                        hotelLine2: 'Desert Resort & Spa'
                    };
                }
            case '010101': 
                if(randVal < .5){
                    return {
                        city: 'Rusutsu',
                        displayCity: 'Hokkaido',
                        country: 'Japan',
                        hotel: 'The Westin Rusutsu Resort',
                        hotelLine1: 'The Westin',
                        hotelLine2: 'Rusutsu Resort'
                    };
                }else{
                    return {
                        city: 'Aurora',
                        displayCity: 'Aurora',
                        country: 'USA',
                        hotel: 'Gaylord Rockies Resort',
                        hotelLine1: 'Gaylord Rockies',
                        hotelLine2: 'Resort & Convention Center'
                    };
                }
            case '010110': return {
                city: 'Bangalore',
                displayCity: 'Bangalore',
                country: 'India',
                hotel: 'The Ritz-Carlton, Bangalore',
                hotelLine1: 'The Ritz-Carlton,',
                hotelLine2: 'Bangalore'
            };
            case '010111': return {
                city: 'Verbier',
                displayCity: 'Verbier',
                country: 'Switzerland',
                hotel: 'W Verbier',
                hotelLine1: 'W',
                hotelLine2: 'Verbier'
            };
            case '011000': 
                if(randVal < .33){
                    return {
                        city: 'Mexico City',
                        displayCity: 'Mexico City',
                        country: 'Mexico',
                        hotel: 'Sheraton Mexico City Maria Isabel Hotel',
                        hotelLine1: 'Sheraton Mexico City',
                        hotelLine2: 'Maria Isabel Hotel'
                    };
                }else{
                    return {
                        city: 'Panama City',
                        displayCity: 'Panama City',
                        country: 'Panama',
                        hotel: 'W Panama',
                        hotelLine1: 'W',
                        hotelLine2: 'Panama'
                    };
                }
            case '011001': 
                if(randVal < .5){
                    return {
                        city: 'Paris',
                        displayCity: 'Paris',
                        country: 'France',
                        hotel: 'Hôtel de Berri Champs-Élysées',
                        hotelLine1: 'Hôtel de Berri Champs-Élysées',
                        hotelLine2: 'a Luxury Collection Hotel, Paris'
                    };
                }else{
                    return {
                        city: 'Beijing',
                        displayCity: 'Beijing',
                        country: 'China',
                        hotel: 'The Ritz-Carlton, Beijing',
                        hotelLine1: 'The Ritz-Carlton,',
                        hotelLine2: 'Beijing'
                    };
                }
            case '011010':
                if(randVal < .33){
                    return {
                        city: 'Pune',
                        displayCity: 'Pune',
                        country: 'India',
                        hotel: 'The Ritz-Carlton, Pune',
                        hotelLine1: 'The Ritz-Carlton,',
                        hotelLine2: 'Pune'
                    };
                }else if(randVal < .66){
                    return {
                        city: 'Bangkok',
                        displayCity: 'Bangkok',
                        country: 'Thailand',
                        hotel: 'JW Marriott Hotel Bangkok',
                        hotelLine1: 'JW Marriott Hotel',
                        hotelLine2: 'Bangkok'
                    };
                }else{
                    return {
                        city: 'Hong Kong',
                        displayCity: 'Hong Kong',
                        country: 'China',
                        hotel: 'JW Marriott Hotel Hong Kong',
                        hotelLine1: 'JW Marriott Hotel',
                        hotelLine2: 'Hong Kong'
                    };
                }
            case '011011': return {
                city: 'Prague',
                displayCity: 'Prague',
                country: 'Czech Republic',
                hotel: 'Prague Marriott Hotel',
                hotelLine1: 'Prague',
                hotelLine2: 'Marriott Hotel'
            };
            case '011100':
                if(randVal < .5){
                    return {
                        city: 'Johannesburg',
                        displayCity: 'Johannesburg',
                        country: 'South Africa',
                        hotel: 'Protea Hotel Johannesburg Wanderers',
                        hotelLine1: 'Protea Hotel',
                        hotelLine2: 'Johannesburg Wanderers'
                    };
                }else{
                    return {
                        city: 'Ho Chi Minh',
                        displayCity: 'Ho Chi Minh',
                        country: 'Vietnam',
                        hotel: 'Sheraton Saigon Hotel & Towers',
                        hotelLine1: 'Sheraton Saigon',
                        hotelLine2: 'Hotel & Towers'
                    };
                }
            case '011101':
                if(randVal < .25){
                    return {
                        city: 'Tokyo',
                        displayCity: 'Tokyo',
                        country: 'Japan',
                        hotel: 'Sheraton Grande Tokyo Bay Hotel',
                        hotelLine1: 'Sheraton Grande',
                        hotelLine2: 'Tokyo Bay Hotel'
                    };
                }else if(randVal < .5){
                    return {
                        city: 'Osaka',
                        displayCity: 'Osaka',
                        country: 'Japan',
                        hotel: 'Aloft Osaka Dojima',
                        hotelLine1: 'Aloft',
                        hotelLine2: 'Osaka Dojima'
                    };
                }else if(randVal < .75){
                    return {
                        city: 'Kyoto2',
                        displayCity: 'Kyoto',
                        country: 'Japan',
                        hotel: 'Hotel The Mitsui, a Luxury Collection Hotel & Spa',
                        hotelLine1: 'Hotel The Mitsui,',
                        hotelLine2: 'a Luxury Collection Hotel & Spa'
                    };
                }else{
                    return {
                        city: 'Kyoto',
                        displayCity: 'Nijo',
                        country: 'Japan',
                        hotel: 'Moxy Kyoto Nijo',
                        hotelLine1: 'Moxy',
                        hotelLine2: 'Kyoto Nijo'
                    };
                }
            case '011110': return {
                city: 'Madrid',
                displayCity: 'Madrid',
                country: 'Spain',
                hotel: 'The Westin Palace, Madrid',
                hotelLine1: 'The Westin Palace,',
                hotelLine2: 'Madrid'
            };
            case '011111': return {
                city: 'Niagara Falls',
                displayCity: 'Niagara Falls',
                country: 'Canada',
                hotel: 'Sheraton Fallsview Hotel',
                hotelLine1: 'Sheraton',
                hotelLine2: 'Fallsview Hotel'
            };
            case '100000':
                if(randVal < .25){
                    return {
                        city: 'Manama',
                        displayCity: 'Manama',
                        country: 'Bahrain',
                        hotel: 'The Ritz-Carlton Bahrain',
                        hotelLine1: 'The Ritz-Carlton',
                        hotelLine2: 'Bahrain'
                    };
                }else if(randVal < .5){
                    return {
                        city: 'Phú Quôc',
                        displayCity: 'Phú Quôc',
                        country: 'Vietnam',
                        hotel: 'Jw Marriott Phú Quôc Emerald Bay',
                        hotelLine1: 'JW Marriott Phu Quoc',
                        hotelLine2: 'Emerald Bay Resort & Spa'
                    };
                }else if(randVal < .75){
                    return {
                        city: 'Muscat2',
                        displayCity: 'Muscat',
                        country: 'Oman',
                        hotel: 'W Muscat',
                        hotelLine1: 'W Muscat',
                        hotelLine2: ''
                    };
                }else{
                    return {
                        city: 'Muscat',
                        displayCity: 'Muscat',
                        country: 'Oman',
                        hotel: 'JW Marriott Muscat',
                        hotelLine1: 'JW Marriott',
                        hotelLine2: 'Muscat'
                    };
                }
            case '100001': return {
                city: 'Addis Ababa',
                displayCity: 'Addis Ababa',
                country: 'Ethiopia',
                hotel: 'Sheraton Addis',
                hotelLine1: 'Sheraton Addis, a Luxury',
                hotelLine2: 'Collection Hotel, Addis Ababa'
            };
            case '100010':
                if(randVal < .33){
                    return {
                        city: 'Miami',
                        displayCity: 'Miami',
                        country: 'USA',
                        hotel: 'W South Beach',
                        hotelLine1: 'W',
                        hotelLine2: 'South Beach'
                    };
                }else if(randVal < .66){
                    return {
                        city: 'Sydney',
                        displayCity: 'Sydney',
                        country: 'Australia',
                        hotel: 'Pier One Sydney Harbour',
                        hotelLine1: 'Pier One Sydney Harbour,',
                        hotelLine2: 'Autograph Collection'
                    };
                }else{
                    return {
                        city: 'Chennai',
                        displayCity: 'Chennai',
                        country: 'India',
                        hotel: 'The Westin Chennai Velachery',
                        hotelLine1: 'The Westin',
                        hotelLine2: 'Chennai Velachery'
                    };
                }
            case '100011': return {
                city: 'Santiago',
                displayCity: 'Santiago',
                country: 'Chile',
                hotel: 'W Santiago',
                hotelLine1: 'W',
                hotelLine2: 'Santiago'
            };
            case '100100':
                if(randVal < .33){
                    return {
                        city: 'Nassau',
                        displayCity: 'Paradise Island',
                        country: 'Bahamas',
                        hotel: 'The Coral at Atlantis',
                        hotelLine1: 'The Coral',
                        hotelLine2: 'at Atlantis'
                    };
                }else if(randVal < .66){
                    return {
                        city: 'San José',
                        displayCity: 'La Ribera de Belen Heredia',
                        country: 'Costa Rica',
                        hotel: 'Costa Rica Marriott Hotel Hacienda Belen',
                        hotelLine1: 'Costa Rica Marriott',
                        hotelLine2: 'Hotel Hacienda Belen'
                    };
                }else{
                    return {
                        city: 'North Island',
                        displayCity: 'North Island',
                        country: 'Seychelles',
                        hotel: 'North Island, a Luxury Collection Resort',
                        hotelLine1: 'North Island, a Luxury',
                        hotelLine2: 'Collection Resort, Seychelles'
                    };
                }
            case '100101': return {
                city: 'Amman',
                displayCity: 'Amman',
                country: 'Jordan',
                hotel: 'The St. Regis Amman',
                hotelLine1: 'The St. Regis',
                hotelLine2: 'Amman'
            };
            case '100110':
                if(randVal < .5){
                    return {
                        city: 'Río Grande',
                        displayCity: 'Río Grande',
                        country: 'Puerto Rico',
                        hotel: 'The St. Regis Bahia Beach Resort',
                        hotelLine1: 'The St. Regis Bahia',
                        hotelLine2: 'Beach Resort, Puerto Rico'
                    };
                }else{
                    return {
                        city: 'Khao Lak',
                        displayCity: 'Khao Lak',
                        country: 'Thailand',
                        hotel: 'JW Marriott Khao Lak Resort & Spa',
                        hotelLine1: 'JW Marriott Khao Lak',
                        hotelLine2: 'Resort & Spa'
                    };
                }
            case '100111': return {
                city: 'Sesimbra',
                displayCity: 'Sesimbra',
                country: 'Portugal',
                hotel: 'Four Points by Sheraton Sesimbra',
                hotelLine1: 'Four Points by Sheraton',
                hotelLine2: 'Sesimbra'
            };
            case '101000': 
                if(randVal < .5){
                    return {
                        city: 'Bali',
                        displayCity: 'Bali',
                        country: 'Indonesia',
                        hotel: 'The Ritz-Carlton, Bali',
                        hotelLine1: 'The Ritz-Carlton,',
                        hotelLine2: 'Bali'
            };
                }else{
                    return {
                        city: 'Bali2',
                        displayCity: 'Nusa Dua',
                        country: 'Bali',
                        hotel: 'The St. Regis Bali Resort',
                        hotelLine1: 'The St. Regis',
                        hotelLine2: 'Bali Resort'
                    };
                }
            case '101001': 
                if(randVal < .5){
                    return {
                        city: 'Cape Town2',
                        displayCity: 'Cape Town',
                        country: 'South Africa',
                        hotel: 'Protea Hotel Fire & Ice! Cape Town',
                        hotelLine1: 'Protea Hotel Fire & Ice!',
                        hotelLine2: 'Cape Town'
                    };
                }else{
                    return {
                        city: 'Cape Town',
                        displayCity: 'Cape Town',
                        country: 'South Africa',
                        hotel: 'The Westin Cape Town',
                        hotelLine1: 'The Westin',
                        hotelLine2: 'Cape Town'
                    };
                }
            case '101010':
                if(randVal < .33){
                    return {
                        city: 'Doha',
                        displayCity: 'Doha',
                        country: 'Qatar',
                        hotel: 'W Doha',
                        hotelLine1: 'W',
                        hotelLine2: 'Doha'
                    };
                }else if(randVal < .66){
                    return {
                        city: 'Jakarta',
                        displayCity: 'Jakarta',
                        country: 'Indonesia',
                        hotel: 'JW Marriott Hotel Jakarta',
                        hotelLine1: 'JW Marriott',
                        hotelLine2: 'Hotel Jakarta'
                    };
                }else{
                    return {
                        city: 'Mumbai',
                        displayCity: 'Mumbai',
                        country: 'India',
                        hotel: 'JW Marriott Mumbai Sahar',
                        hotelLine1: 'JW Marriott',
                        hotelLine2: 'Mumbai Sahar'
                    };
                }
            case '101011':
                if(randVal < .5){
                    return {
                        city: 'Kosgoda',
                        displayCity: 'Kosgoda',
                        country: 'Sri Lanka',
                        hotel: 'Sheraton Kosgoda Turtle Beach Resort',
                        hotelLine1: 'Sheraton Kosgoda',
                        hotelLine2: 'Turtle Beach Resort'
                    };
                }else{
                    return {
                        city: 'Gifu',
                        displayCity: 'Gifu',
                        country: 'Japan',
                        hotel: 'Moxy Gifu Takayama Shokawa',
                        hotelLine1: 'Fairfield by Marriott Gifu',
                        hotelLine2: 'Takayama Shokawa'
                    };
                }
            case '101100':
                if(randVal < .5){
                    return {
                        city: 'Honolulu',
                        displayCity: 'Honolulu',
                        country: 'Hawaii',
                        hotel: 'Sheraton Waikiki',
                        hotelLine1: 'Sheraton',
                        hotelLine2: 'Waikiki'
                    };
                }else{
                    return {
                        city: 'Palm Beach',
                        displayCity: 'Palm Beach',
                        country: 'Aruba',
                        hotel: 'The Ritz-Carlton, Aruba',
                        hotelLine1: 'The Ritz-Carlton,',
                        hotelLine2: 'Aruba'
                    };
                }
            case '101101':
                if(randVal < .5){
                    return {
                        city: 'Albufeira',
                        displayCity: 'Albufeira',
                        country: 'Portugal',
                        hotel: 'W Algarve',
                        hotelLine1: 'W',
                        hotelLine2: 'Algarve'
                    };
                }else{
                    return {
                        city: 'Nikko',
                        displayCity: 'Nikko',
                        country: 'Japan',
                        hotel: 'The Ritz-Carlton, Nikko',
                        hotelLine1: 'The Ritz-Carlton,',
                        hotelLine2: 'Nikko'
                    };
                }
            case '101110':
                if(randVal < .33){
                    return {
                        city: 'Fari Islands',
                        displayCity: 'Fari Islands',
                        country: 'Maldives',
                        hotel: 'The Ritz-Carlton, Maldives',
                        hotelLine1: 'The Ritz-Carlton Maldives,',
                        hotelLine2: 'Fari Islands'
                    };
                }else if(randVal < .66){
                    return {
                        city: 'Vommuli',
                        displayCity: 'Vommuli',
                        country: 'Maldives',
                        hotel: 'The St. Regis Maldives Vommuli Resort',
                        hotelLine1: 'The St. Regis Maldives',
                        hotelLine2: 'Vommuli Resort'
                    };
                }else{
                    return {
                        city: 'Los Cabos',
                        displayCity: 'Los Cabos',
                        country: 'Mexico',
                        hotel: 'JW Marriott Los Cabos',
                        hotelLine1: 'JW Marriott Los Cabos',
                        hotelLine2: 'Beach Resort & Spa'
                    };
                }
            case '101111': return {
                city: 'Crete',
                displayCity: 'Crete',
                country: 'Greece',
                hotel: 'Blue Palace Elounda',
                hotelLine1: 'Blue Palace Elounda, a',
                hotelLine2: 'Luxury Collection Resort, Crete'
            };
            case '110000':
                if(randVal < .5){
                    return {
                        city: 'Singapore',
                        displayCity: 'Orchard Road',
                        country: 'Singapore',
                        hotel: 'Singapore Marriott Tang Plaza Hotel',
                        hotelLine1: 'Singapore Marriott',
                        hotelLine2: 'Tang Plaza Hotel'
                    };
                }else{
                    return {
                        city: 'Cairo',
                        displayCity: 'Cairo',
                        country: 'Egypt',
                        hotel: 'The Nile Ritz-Carlton, Cairo',
                        hotelLine1: 'The Nile Ritz-Carlton,',
                        hotelLine2: 'Cairo'
                    };
                }
            case '110001':
                if(randVal < .33){
                    return {
                        city: 'Warsaw',
                        displayCity: 'Warsaw',
                        country: 'Poland',
                        hotel: 'Sheraton Grand Warsaw',
                        hotelLine1: 'Sheraton Grand',
                        hotelLine2: 'Warsaw'
                    };
                }else if(randVal < .66){
                    return {
                        city: 'London2',
                        displayCity: 'London',
                        country: 'UK',
                        hotel: 'W London',
                        hotelLine1: 'W London',
                        hotelLine2: ''
                    };
                }else{
                    return {
                        city: 'London',
                        displayCity: 'London',
                        country: 'UK',
                        hotel: 'London Marriott Hotel Park Lane',
                        hotelLine1: 'London Marriott Hotel',
                        hotelLine2: 'Park Lane'
                    };
                }
            case '110010':
                if(randVal < .5){
                    return {
                        city: 'Petaling Jaya',
                        displayCity: 'Petaling Jaya',
                        country: 'Malaysia',
                        hotel: 'Sheraton Petaling Jaya Hotel',
                        hotelLine1: 'Sheraton',
                        hotelLine2: 'Petaling Jaya Hotel'
                    };
                }else{
                    return {
                        city: 'Kuala Lumpur',
                        displayCity: 'Kuala Lumpur',
                        country: 'Malaysia',
                        hotel: 'W Kuala Lumpur',
                        hotelLine1: 'W',
                        hotelLine2: 'Kuala Lumpur'
                    };
                }
            case '110011': return {
                city: 'Vancouver',
                displayCity: 'Vancouver',
                country: 'Canada',
                hotel: 'JW Marriott Parq Vancouver',
                hotelLine1: 'JW Marriott',
                hotelLine2: 'Parq Vancouver'
            };
            case '110100':
                if(randVal < .5){
                    return {
                        city: 'New Delhi',
                        displayCity: 'New Delhi',
                        country: 'India',
                        hotel: 'JW Marriott Hotel New Delhi Aerocity',
                        hotelLine1: 'JW Marriott Hotel',
                        hotelLine2: 'New Delhi Aerocity'
                    };
                }else{
                    return {
                        city: 'Riyadh',
                        displayCity: 'Riyadh',
                        country: 'Saudi Arabia',
                        hotel: 'Le Méridien Riyadh',
                        hotelLine1: 'Le Méridien',
                        hotelLine2: 'Riyadh'
                    };
                }
            case '110101': return {
                city: 'Warsaw',
                displayCity: 'Warsaw',
                country: 'Poland',
                hotel: 'Sheraton Grand Warsaw',
                hotelLine1: 'Sheraton Grand',
                hotelLine2: 'Warsaw'
            };
            case '110110':
                if(randVal < .5){
                    return {
                        city: 'Austin',
                        displayCity: 'Austin',
                        country: 'USA',
                        hotel: 'JW Marriott Austin',
                        hotelLine1: 'JW Marriott',
                        hotelLine2: 'Austin'
                    };
                }else{
                    return {
                        city: 'Pretoria',
                        displayCity: 'Pretoria',
                        country: 'South Africa',
                        hotel: 'The Sheraton Pretoria Hotel',
                        hotelLine1: 'Sheraton',
                        hotelLine2: 'Pretoria Hotel'
                    };
                }
            case '110111': return {
                city: 'Bucharest',
                displayCity: 'Bucharest',
                country: 'Romania',
                hotel: 'JW Marriott Bucharest Grand Hotel',
                hotelLine1: 'JW Marriott Bucharest',
                hotelLine2: 'Grand Hotel'
            };
            case '111000': return {
                city: 'Limassol',
                displayCity: 'Limassol',
                country: 'Cyprus',
                hotel: 'Parklane Limassol',
                hotelLine1: 'Parklane, a Luxury Collection',
                hotelLine2: 'Resort & Spa, Limassol'
            };
            case '111001':
                if(randVal < .5){
                    return {
                        city: 'Tbilisi',
                        displayCity: 'Tbilisi',
                        country: 'Georgia',
                        hotel: 'Moxy Tbilisi',
                        hotelLine1: 'Moxy',
                        hotelLine2: 'Tbilisi'
                    };
                }else{
                    return {
                        city: 'Kathmandu',
                        displayCity: 'Kathmandu',
                        country: 'Nepal',
                        hotel: 'Kathmandu Marriott Hotel',
                        hotelLine1: 'Kathmandu',
                        hotelLine2: 'Marriott Hotel'
                    };
                }
            case '111010': return {
                city: 'Cusco',
                displayCity: 'Cusco',
                country: 'Peru',
                hotel: 'Palacio del Inka',
                hotelLine1: 'Palacio del Inka, a Luxury',
                hotelLine2: 'Collection Hotel, Buenos Aires'
            };
            case '111011': return {
                city: 'Copenhagen',
                displayCity: 'Copenhagen',
                country: 'Denmark',
                hotel: 'Nobis Hotel Copenhagen',
                hotelLine1: 'Nobis Hotel Copenhagen,',
                hotelLine2: 'A Member of Design Hotels™'
            };
            case '111100':
                if(randVal < .5){
                    return {
                        city: 'Budapest',
                        displayCity: 'Budapest',
                        country: 'Hungary',
                        hotel: 'The Ritz-Carlton Budapest',
                        hotelLine1: 'The Ritz-Carlton,',
                        hotelLine2: 'Budapest'
                    };
                }else{
                    return {
                        city: 'Dhaka',
                        displayCity: 'Dhaka',
                        country: 'Bangladesh',
                        hotel: 'Sheraton Dhaka',
                        hotelLine1: 'Sheraton',
                        hotelLine2: 'Dhaka'
                    };
                }
            case '111101':
                if(randVal < .5){
                    return {
                        city: 'Berlin',
                        displayCity: 'Berlin',
                        country: 'Germany',
                        hotel: 'The Ritz-Carlton, Berlin',
                        hotelLine1: 'The Ritz-Carlton,',
                        hotelLine2: 'Berlin'
                    };
                }else{
                    return {
                        city: 'Thimphu',
                        displayCity: 'Thimphu',
                        country: 'Bhutan',
                        hotel: 'Le Méridien Thimphu',
                        hotelLine1: 'Le Méridien',
                        hotelLine2: 'Thimphu'
                    };
                }
            case '111110':
                if(randVal < .33){
                    return {
                        city: 'Los Angeles',
                        displayCity: 'West Hollywood',
                        country: 'USA',
                        hotel: 'The West Hollywood EDITION',
                        hotelLine1: 'The West Hollywood',
                        hotelLine2: 'EDITION'
                    };
                }else if(randVal < .66){
                    return {
                        city: 'Ikeja',
                        displayCity: 'Lagos',
                        country: 'Nigeria',
                        hotel: 'Lagos Marriott Hotel Ikeja',
                        hotelLine1: 'Lagos Marriott',
                        hotelLine2: 'Hotel Ikeja'
                    };
                }else{
                    return {
                        city: 'Abu Dhabi',
                        displayCity: 'Abu Dhabi',
                        country: 'United Arab Emirates',
                        hotel: 'The Abu Dhabi EDITION',
                        hotelLine1: 'The Abu Dhabi',
                        hotelLine2: 'EDITION'
                    };
                }
            case '111111': return {
                city: 'Geneva',
                displayCity: 'Geneva',
                country: 'Switzerland',
                hotel: 'The Ritz-Carlton Hotel de la Paix, Geneva',
                hotelLine1: 'The Ritz-Carlton Hotel',
                hotelLine2: 'de la Paix, Geneva'
            };
          }
      }
  }
}
</script>

<style scoped>
.destination__mobile-img{
  display: none;
}
.bonvoy-frame--external{
    width: 45%;
    max-width: 616px;
    margin-right: 6.5%;
    margin-left: auto;
    margin-top: 159px;
    margin-bottom: 82px;
    background-color: white;
    position: relative;
    z-index: 1000;
}

.bonvoy-frame--internal{
    min-height: 60vh;
    min-height: calc(var(--vh, 1vh) * 60);
    padding-bottom: 47px;
}

.destination{
    width: 100%;
    overflow-x: hidden;
    position: relative;
    min-height: calc(100vh - 70px);
    min-height: calc(var(--vh, 1vh) * 100 - 70px);
}

.destination__logo{
    margin-top: 67px;
    width: 210px;
}

.destination__h1{
    color: #1C1C1C;
    font-family: "Aldine-721-LightItalic";
    font-size: 38px;
    font-style: italic;
    font-weight: 300;
    letter-spacing: 0.53px;
    line-height: 44px;
    text-align: center;
    margin-top: 48px;
}

.destination__h2, .destination__h3{
  color: #010101;
  font-family: "Swiss-721-BoldCondensed";
  font-size: 22px;
  letter-spacing: 0.31px;
  line-height: 43px;
  text-align: center;
}

.destination__h3 {
    width: 366px;
    max-width: 100%;
    background-color: #ffdaa0;
    border-radius: 19.5px;
}

.destination__p{
    margin-top: 23px;
    margin-bottom: 30px;
    font-family: "Aldine-721-LightItalic";
    font-size: 24px;
    font-style: italic;
    letter-spacing: 0.33px;
    line-height: 30px;
    text-align: center;
    max-width: 422px;
}

.destination__accent{
    position: absolute;
    top: 68%;
    left: 10%;
    color: #FFFFFF;
    font-family: "Aldine-721-LightItalic";
    font-size: 60px;
    font-style: italic;
    font-weight: 300;
    letter-spacing: 0.83px;
    line-height: 72px;
    text-shadow: 0 0 100px black;
    text-align: left;
    text-wrap: nowrap;
}

.destination__accent-pin{
  height: 40px;
  width: auto;
  position: absolute;
  top: 20px;
  left: -55px;
}

.destination__h3-pin{
  height: 25px;
  margin-bottom: 7px;
  margin-right: -5px;
}

.destination__accent hr{
    position: absolute;
    width: 100vw;
    max-width: none;
    border-bottom: 2px solid white;
    margin: 0px;
    left: -55px;
    top: calc(50% - 1px);
}

.destination__accent hr::before{
    display: block;
    content: "";
    width: 2px;
    height: 12px;
    background-color: white;
    margin-top: -5px;
}


@media (max-width: 1130px){
    .bonvoy-frame--external{
        width: 60%;
    }

    .destination__accent{
        right: 70%;
    }
}

@media (max-width: 1000px){
    .bonvoy-frame--external{
        width: 80%;
        margin-right: auto;
    }

    .destination__accent{
        display: none;
    }
}

@media (max-width: 640px){
    .destination__h1{
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 25px;
    }

    .destination__h2, .destination__h3{
        font-size: 20px;
        line-height: 26px;
    }

    .destination__h3{
        width: 335px;
    }

    .destination__p{
        font-size: 20px;
        line-height: 20px;
        max-width: 335px;
    }

    .destination__mobile-img{
      display: block;
    }
}

@media (max-width: 540px){
    .bonvoy-frame--external{
        width: 95%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

}

@media (max-width: 440px){
    .destination__h2, .destination__h3{
        font-size: 16px;
        line-height: 26px;
    }

    .destination__h3{
        width: 270px;
    }
}

@media (max-width: 440px) and (max-height: 680px){
    .bonvoy-frame--external{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

</style>
