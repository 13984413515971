<template>
<div class="instructions__wrapper">
  <div class="bonvoy-frame--external">
    <div class="bonvoy-frame--internal">
      <div class="instructions__dislike">
        <p>I Do Not Like</p>
      </div>
      <img class="instructions__dislike-img" src="../assets/img/swipe-left.png">
      <div class="instructions__like">
        <p>I Like</p>
      </div>
      <img class="instructions__like-img" src="../assets/img/swipe-right.png">
      <div class="grid-container">
          <img class="instructions__logo" src="../assets/img/bonvoy-logo--reverse.png" @click="$emit('changeStep','splash')">
          <div class="grid-x">
            <h1 v-if="firstName != 'Guest'" class="instructions__h1 mx-auto">Welcome, {{firstName}}</h1>
            <h1 v-if="firstName == 'Guest'" class="instructions__h1 mx-auto">Welcome</h1>
          </div>
          <div class="grid-x">
              <h2 class="instructions__h2 mx-auto">How to Play</h2>
          </div>
          <div class="grid-x">
              <p class="cell small-10 small-offset-1 medium-6 medium-offset-3 instructions__p mx-auto">
                Take a trip through the following images. Swipe RIGHT on the ones that catch your eye, and LEFT on the ones that don’t, to be matched with your ideal travel destination. Wherever it is, we’ll meet you there. Where Can We Take You?
              </p>
          </div>
          <div class="grid-x">
              <button class="button--orange mx-auto" @click="$emit('changeStep', 'swipe')" tabindex="0">Start</button>
          </div>
      </div>
    </div>
  </div>
  <div class="instructions__restart">
    <img class="instructions__restart-img" src="../assets/img/restart.png">
    <button class="bonvoy-link" @click="$emit('changeStep', 'splash')">Start Over</button>
  </div>
</div>
</template>

<script>
export default {
  name: 'Instructions',
  emits: ["changeStep"],
  props: {
    firstName: String
  }
}
</script>

<style scoped>
.bonvoy-frame--internal{
  background: url(../assets/img/instructions-bg.jpg);
  background-size: cover;
  min-height: calc(100vh - 165px);
  min-height: calc(var(--vh, 1vh) * 100 - 165px);
  position: relative;
  padding-bottom: 120px;
}

.instructions__logo{
  width: 60%;
  max-width: 280px;
  margin-top: 106px;
}

.instructions__h1{
  font-family: "Aldine-721-LightItalic";
  margin-top: 88px;
  font-size: 72px;
  line-height: 86px;
  letter-spacing: 1px;
  color: white;
}

.instructions__h2{
  font-family: "Swiss-721-BoldCondensed";
  margin-top: 46px;
  font-size: 29px;
  line-height: 35px;
  margin-bottom: 21px;
  text-transform: uppercase;
  padding-left: 5%;
  padding-right: 5%;
  color: white;
}

.instructions__p{
  font-family: "Swiss-721-Regular";
  size: 16px;
  line-height: 27px;
  margin-bottom: 45px;
  color: white;
}

.instructions__restart{
  margin-left: 44px;
  margin-right: auto;
  text-align: left;
  margin-top: 32px;
  margin-bottom: -14px;
}

.instructions__restart-img{
  width: 29px;
  height: 29px;
  margin-right: 15px;
}

.instructions__dislike{
  position: absolute;
  top: 350px;
  left: 0px;
  width: 300px;
  border-bottom: 1px solid white;
  color: white;
  transform: rotateZ(-90deg);
}

.instructions__like{
  position: absolute;
  top: 350px;
  right: 0px;
  width: 300px;
  border-bottom: 1px solid white;
  color: white;
  transform: rotateZ(90deg);
}

.instructions__dislike p, .instructions__like p {
  font-family: "Swiss-721-BoldCondensed";
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.instructions__dislike-img{
  position: absolute;
  top: 135px;
  left: 135px;
}

.instructions__like-img{
  position: absolute;
  top: 135px;
  right: 135px;
}

.instructions__wrapper{
  padding-top: 52px;
}

@media (max-width: 1200px){
  .instructions__h1{
    font-size: 48px;
    line-height: 66px;
  }

  .instructions__dislike{
    left: -80px;
  }

  .instructions__like{
    right: -80px;
  }

  .instructions__dislike-img{
    left: 55px;
  }

  .instructions__like-img{
    right: 55px;
  }
}

@media (max-width: 780px){


  .instructions__h2{
    font-size: 18px;
    line-height: 38px;
  }

  .instructions__dislike, .instructions__dislike-img, .instructions__like, .instructions__like-img{
    display: none;
  }

  .instructions__restart{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media (max-width: 640px){
  .instructions__h1{
    font-size: 36px;
    line-height: 55px;
  }
.instructions__wrapper{
  padding-top: 20px;
}
}

@media (max-width: 480px){
  .instructions__h1{
    font-size: 24px;
    line-height: 36px;
    margin-top: 48px;
  }

  .instructions__logo{
    margin-top: 65px;
  }
}
</style>
