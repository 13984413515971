<template>
    <div class="crm__wrapper">
    <form class="crm__form" @submit="$event.preventDefault()">
        <img class="crm__header-img" src="../assets/img/bonvoy-logo.png" @click="$emit('changeStep','splash')">
        <img class="crm__header-img-accent" src="../assets/img/path-connector.png">
        <div class="crm__header-join">
					Enroll now to earn 500 bonus points instantly.
        </div>
        <iframe src="https://jmr2.netlinkrg.com/dreamforce2/">
        </iframe>
    </form>
        <button class="button--black next-step" @click="$emit('changeStep', 'crm')">Next Step</button>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Register',
  emits: ["changeStep", "setName"],
  data() {
      return {
          firstName: null,
          lastName: null,
          email: null,
          accountNumber: null,
          error: null,
          matchmakerLegalAgree: false,
          generalLegalAgree: false,
          showTermsModal: false,
          showGeneralTermsModal: false,
          showBonvoyTermsModal: false,
          showPrivacyPolicyModal: false
      }
  },
  methods: {
      validate: function(e){
          e.preventDefault();
          console.log("validate attempt");
          if(this.firstName == null || this.firstName == ""){
            this.error = "First name is required.";
            return;
          }
          if(this.lastName == null || this.lastName == ""){
            this.error = "Last name is required.";
            return;
          }
          if(this.email == null || this.email == ""){
            this.error = "Email is required.";
            return;
          }
          if(!this.email.match(/^\S+@\S+\.\S+$/)){
             this.error = "Email must be valid.";
             return;
          }
          if(this.accountNumber == null || this.accountNumber == ""){
             this.error = "Account number is required.";
             return;
          }
          if(this.accountNumber.length < 8){
            this.error = "Account number must be at least 8 digits.";
            return;
          }
          if(this.matchmakerLegalAgree != true){
            this.error = "You must agree to Destination Matchmaker terms and conditions.";
            return;
          }

          if(this.generalLegalAgree != true){
            this.error = "You must agree to Marriott Bonvoy terms and conditions.";
            return;
          }
        this.error = "";
        axios.post("/api/submitUserToMarriott/", {first_name: this.firstName, last_name: this.lastName, email: this.email, member_number: this.accountNumber, is_terms_accepted: this.matchmakerLegalAgree && this.generalLegalAgree})
        .then(response => {
            console.log(response);
            this.$emit('setName', this.firstName);
            this.$emit('changeStep', 'instructions');
        });
      }
  }
}
</script>

<style scoped>
.next-step{
    margin-right: 44px;
    margin-left: auto;
    margin-top: 44px;
    display: block;
}
.crm__form{ 
  height: calc(var(--vh, 1vh) * 80);
}
.terms-link{
    color: #B84c16;
    text-decoration: underline;
}

.terms-modal--wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.4);
}

.terms-modal--wrapper button{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
}

.terms-modal iframe{
    width: 100%;
    height: 100%;
}
.terms-modal{
    position: fixed;
    width: 90%;
    height: calc(95% - 100px);
    top: 5%;
    left: 5%;
    background-color: white;
    border: 2px solid black;
    overflow-y: auto;
    text-align: left;
}

.terms-modal--iframe{
    overflow-y: hidden;
}

.double-stack{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    width: 76%;
    margin: auto;
}

.double-stack-alt{
    display: none;
}

.crm__form .double-stack input, .crm__form .double-stack label{
    width: 100%;
}
.crm__error{
    margin-top: 20px;
    color: red;
    font-size: 20px;
}

.crm__wrapper{
  padding-top: 54px;
}
.crm__form{
  width: 54%;
  margin-left: auto;
  margin-right: 44px;
  background: white;
  box-shadow: 0 0 14px 0 rgba(0,0,0,0.14);
  min-width: 730px;
  border-radius: 30px;
}

.crm__label, .crm__form input{
  width: 76%;
  margin: auto;
  text-align: left;
  color: #000000;
  font-family: "Swiss-721-Regular";
  font-size: 16px;
  letter-spacing: 0.22px;
  line-height: 19px;
}

.crm__label{
  margin-top: 23px;
  margin-bottom: 11px;
}

.crm__h1{
  width: 76%;
  margin: auto;
  color: black;
  font-family: "Swiss-721-BoldCondensed";
  font-size: 30px;
  letter-spacing: 1.42px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 43px;
}

.crm__form .button--black{
  margin-top: 70px;
  margin-bottom: 10px;
  min-width: 160px;
}

.crm__error{
    padding-bottom: 90px;
}

.crm__checkbox-wrapper{
  width: 76%;
  margin: auto;
  text-align: left;
  user-select: none; 
  color: #000000;
  font-family: "Swiss-721-Regular";
  font-size: 13px;
  letter-spacing: 0.18px;
}

.crm__checkbox-wrapper--first{
  margin-top: 47px;
  margin-bottom: 19px;
}

.crm__checkbox-wrapper .control{
  line-height: 16px;
  overflow: visable;
}

.crm__header-img{
    position: absolute;
    left: 11%;
    top: 38%;
    width: 20%;
    max-width: 276px;
}

.crm__header-img-accent{
    position: absolute;
    pointer-events: none;
    width: 40%;
    left: 11%;
    transform: translateX(-75%);
    max-width: 554px;
    top: 43%;
}

.crm__header-join{
    position: absolute;
    left: 11%;
    top: 65%;
    width: 20%;
    max-width: 276px;

    color: #000000;
    font-family: "Swiss-721-BoldCondensed";
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: 0.22px;
    line-height: 24px;
    text-align: center;
}

.mobile-join{
    color: #000000;
    font-family: "Swiss-721-Regular";
    font-size: 16px;
    letter-spacing: 0.22px;
    line-height: 19px;
    text-align: center;
    display: none;
}

.crm__form iframe{
    width: 95%;
    margin-left: 5%;
    border: none;
    height: 100%;
}

@media (max-width: 1170px){
    .mobile-join{
        display: block;
    }

    .crm__header-join{
        position: static;
        margin-left: auto;
        margin-right: auto;
        width: 95%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .crm__form iframe{
        width: 100%;
        margin-left: 0;
        height: calc(100% - 150px);
    }

    .next-step{
        margin-right: auto;
    }

    .crm__header-img{
        position: static;
        width: 250px;
    }

    .crm__header-img-accent{
        display: none;
    }

    .crm__form{
        padding-top: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    .crm__h1{
        margin-top: 25px;
        margin-bottom: 10px;
    }

    .crm__form .button--black{
        margin-top: 35px;
        margin-bottom: 10px;
    }

    .crm__error{
        padding-bottom: 40px;
    }
}

@media (max-width: 745px){
    .crm__form{
        width: calc(100% - 40px);
        min-width: 0px;
    }

    .crm__h1{
        font-size: 18px;
    }

    .crm__header-img{
        width: 200px;
    }
    
    .crm__wrapper{
        padding-top: 20px;
    }

    .double-stack{
        display: none;
    }

    .double-stack-alt{
        display: block;
    }
}

@media (max-width: 500px){
    .crm__label, .crm__form input, .crm__h1, .crm__checkbox-wrapper{
        width: 90%;
    }

    .crm__checkbox-wrapper .control{
        font-size: 12px;
        line-height: 12px;
    }
}

@media(max-width: 430px){
    .crm__h1{
        font-size: 14px;
        margin-bottom: 0px;
    }
}

/* custom checkbox */
.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
}
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
    }
.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: none;
    border: 2px solid #707070;
    border-radius: 0px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: none;
}

.control input:checked ~ .control_indicator {
    background: none;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: none;
}
.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-checkbox .control_indicator:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 9px;
    border: solid #ff9662;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
}

</style>
