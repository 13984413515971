<template>
<div class="splash__wrapper">
<div class="bonvoy-frame--external">
  <div class="bonvoy-frame--internal">
    <div class="grid-container">
        <img class="splash__logo" src="../assets/img/bonvoy-logo.png">
        <div class="grid-x">
          <p class="splash__p cell small-12 medium-8 medium-offset-2">Welcome to Marriott Bonvoy<sup>®</sup>. Experience the extraordinary at thousands of distinctive hotels close to home and in the world’s most inspiring destinations.</p>
        </div>
        <div class="grid-x">
          <h2 class="splash__h2 cell small-12 medium-7 mx-auto">Play our Destination Matchmaker game now to find out more.<br/>Join Marriott Bonvoy today to earn 500 Bonus Points.</h2>
        </div>
        <h1 class="splash__h1"></h1>
        <div class="grid-x">
          <div class="splash__button-container">
            <button class="button--black" @click="$emit('changeStep', 'register')" tabindex="0">
              Join Marriott Bonvoy
              <div class="button-caption">(For Free)</div>
            </button>
            <button class="button--white" @click="$emit('changeStep', 'crm')" tabindex="0">
              Play Now
              <div class="button-caption">(As a Member)</div>
              </button>
          </div>
        </div>
        <div class="mx-auto splash__link-container">
          <button class="bonvoy-link" @click="$emit('setName', 'Guest'); $emit('changeStep', 'instructions')" tabindex="0">Continue as a guest.<br/></button>
        </div>
    </div>
  </div>
</div>
<div v-if="showSignupModal" class="signup-modal--wrapper">
  <div class="signup-modal">
    <iframe src="https://www.joinmarriottbonvoy.com/dreamforce2/" scrolling="yes"></iframe>
  </div>
  <button class="button--black" @click="showSignupModal = false">
    Done
  </button>
</div>
</div>
</template>

<script>
export default {
  name: 'Splash',
  emits: ["changeStep", "setName"],
  data(){
    return{
      showSignupModal: false
    }
  }
}
</script>

<style scoped>
.signup-modal--wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.4);
}

.signup-modal--wrapper button{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
}
.signup-modal{
    position: fixed;
    width: 90%;
    height: calc(95% - 100px);
    top: 5%;
    left: 5%;
    background-color: white;
    border: 2px solid black;
    overflow-y: hidden;
    text-align: left;
}

.signup-modal iframe{
  width: 100%;
  height: 100%;
}
.splash__logo{
  width: 60%;
  max-width: 280px;
  margin-top: 130px;
}

.splash__p{
  font-family: "Swiss-721-Light";
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: 44px;
}

.splash__h1{
  font-family: "Aldine-721-LightItalic";
  margin-top: 32px;
  margin-bottom: 56px;
  font-size: 48px;
  letter-spacing: 0.67px;
  line-height: 57px;
  color: #010101;
}

.splash__h2{
  font-family: "Swiss-721-BoldCondensed";
  margin-top: 42px;
  font-size: 18px;
  line-height: 29px;
  text-transform: uppercase;
  padding-left: 5%;
  padding-right: 5%;
  color: #010101;
}

.splash__button-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.splash__button-container button{
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 87px;
  width: 276px;
  position: relative;
}

.splash__button-container .button-caption{
  position: absolute;
  bottom: 0;
  left: 50%;
  font-family: "Aldine-721-LightItalic";
  font-size: 18px;
  text-transform: none;
  transform: translateX(-50%) translateY(100%);
  color: #010101;
  padding-top: 8px;
}

.splash__link-container{
  padding-bottom: 40px;
}

.splash__wrapper{
  padding-top: 52px;
}

.bonvoy-link{
  font-size: 20px;
}
.bonvoy-link br{
  display: none;
}

@media (max-width: 780px){
  .splash__h2{
    font-size: 18px;
    line-height: 25px;
  }
}

@media (max-width: 640px){
  .splash__h1{
    font-size: 36px;
    line-height: 55px;
  }
  .splash__button-container{
    flex-direction: column;
    align-items: center;
  }

  .splash__wrapper{
    padding-top: 20px;
  }

  .bonvoy-link{
    font-size: 16px;
  }

  .splash__button-container button{
    margin-bottom: 40px;
  }

  .bonvoy-link br{
    display: block;
  }
}

@media (max-width: 480px){
  .splash__h1{
    font-size: 40px;
    line-height: 40px;
    margin-top: 48px;
  }

  .splash__logo{
    margin-top: 65px;
  }
}

@media (max-width: 325px){
  .splash__button-container button{
    width: 100%;
  }
}
</style>
