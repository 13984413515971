<template>
<div ref="tinder" class="tinder">
  <div class="tinder--status">
    <i class="fa fa-remove"></i>
    <i class="fa fa-heart"></i>
  </div>

  <div ref="tinderContainer" class="tinder--cards">
    <div v-for="(card, index) in cards" class="tinder--card" :class="card.removed ? 'removed' : ''" :key="index" :style="'z-index: ' + (cards.length - index)" :id="card.id">
      <img :src="card.src" class="desktop-only">
      <img :src="card.mobileSrc" class="mobile-only">
      <!--<h3>{{card.title}}</h3>
      <p>{{card.description}}</p>-->
    </div>
  </div>
  <div class="tinder__frame-overlay"></div>
  <div class="tinder__counter-numerator">{{10 > curCard ? '0' + curCard : curCard}}</div>
  <div class="tinder__counter-denominator">/10</div>
  <div class="swipe__legal">
    © 1996-2023 Marriott International, Inc.<br /> All rights reserved. Marriott Proprietary Information.
  </div>
  <div class="swipe__instructions">
    <button class="swipe__instructions-button" @click="showInstructions = true">How to Play</button>
  </div>
  <div v-if="showInstructions" class="swipe__instructions-modal-wrapper">
    <div class="swipe__instructions-modal">
      <div class="swipe__instructions-modal-inner">
        <button class="swipe__instructions-close" @click="showInstructions = false">X</button>
        <div class="instructions__dislike">
          <p>I Do Not Like</p>
        </div>
        <img class="instructions__dislike-img" src="../assets/img/swipe-left.png">
        <div class="instructions__like">
          <p>I Like</p>
        </div>
        <img class="instructions__like-img" src="../assets/img/swipe-right.png">
        <div class="grid-container">
            <div class="grid-x">
                <h2 class="instructions__h2 mx-auto">How to Play</h2>
            </div>
            <div class="grid-x">
                <p class="cell small-10 small-offset-1 medium-6 medium-offset-3 instructions__p mx-auto">To calculate your ideal travel destination, we will show you a series of images. All you have to do is swipe RIGHT for places that you like and swipe LEFT for those that don’t inspire.</p>
            </div>
            <div class="grid-x">
                <button class="button--black-outlined mx-auto" @click="showInstructions = false" tabindex="0">Start</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Hammer from 'hammerjs';
export default {
  name: 'Swipe',
  emits: ["changeStep", "setPrefs"],
  props: {
    msg: String
  },
  data() {
    return {
      isDesktop: false,
      cards: [
        {
          id: "hot-01",
          src: "./swipe-cards--desktop/hot-01.jpg",
          mobileSrc: "./swipe-cards--mobile/hot-01.jpg",
          removed: false,
          adventurous: 0,
          beach: 0,
          action: 0,
          city: 0,
          ancient: 0,
          hot: 1
        },
        {
          id: "adventure-01",
          src: "./swipe-cards--desktop/adventure-01.jpg",
          mobileSrc: "./swipe-cards--mobile/adventure-01.jpg",
          removed: false,
          adventurous: 1,
          beach: 0,
          action: 0,
          city: 0,
          ancient: 0,
          hot: 0
        },
        {
          id: "beach-01",
          src: "./swipe-cards--desktop/beach-01.jpg",
          mobileSrc: "./swipe-cards--mobile/beach-01.jpg",
          removed: false,
          adventurous: 0,
          beach: 1,
          action: 0,
          city: 0,
          ancient: 0,
          hot: 0
        },
        {
          id: "action-01",
          src: "./swipe-cards--desktop/action-01.jpg",
          mobileSrc: "./swipe-cards--mobile/action-01.jpg",
          removed: false,
          adventurous: 0,
          beach: 0,
          action: 1,
          city: 0,
          ancient: 0,
          hot: 0
        },
        {
          id: "ancient-01",
          src: "./swipe-cards--desktop/ancient-01.jpg",
          mobileSrc: "./swipe-cards--mobile/ancient-01.jpg",
          removed: false,
          adventurous: 0,
          beach: 0,
          action: 0,
          city: 0,
          ancient: 1,
          hot: 0
        },
        {
          id: "city-01",
          src: "./swipe-cards--desktop/city-01.jpg",
          mobileSrc: "./swipe-cards--mobile/city-01.jpg",
          removed: false,
          adventurous: 0,
          beach: 0,
          action: 0,
          city: 1,
          ancient: 0,
          hot: 0
        },
        {
          id: "cold-01",
          src: "./swipe-cards--desktop/cold-01.jpg",
          mobileSrc: "./swipe-cards--mobile/cold-01.jpg",
          removed: false,
          adventurous: 0,
          beach: 0,
          action: 0,
          city: 0,
          ancient: 0,
          hot: -1
        },
        {
          id: "culture-01",
          src: "./swipe-cards--desktop/culture-01.jpg",
          mobileSrc: "./swipe-cards--mobile/culture-01.jpg",
          removed: false,
          adventurous: 0,
          beach: 0,
          action: -1,
          city: 0,
          ancient: 0,
          hot: 0
        },
        {
          id: "nature-01",
          src: "./swipe-cards--desktop/nature-01.jpg",
          mobileSrc: "./swipe-cards--mobile/nature-01.jpg",
          removed: false,
          adventurous: 0,
          beach: 0,
          action: 0,
          city: -1,
          ancient: 0,
          hot: 0
        },
        {
          id: "relax-01",
          src: "./swipe-cards--desktop/relax-01.jpg",
          mobileSrc: "./swipe-cards--mobile/relax-01.jpg",
          removed: false,
          adventurous: -1,
          beach: 0,
          action: 0,
          city: 0,
          ancient: 0,
          hot: 0
        }
      ],
      prefs: {
          adventurous: 0,
          beach: 0,
          action: 0,
          city: 0,
          ancient: 0,
          hot: 0
      },
      showInstructions: false,
      curCard: 1
    }
  },
  mounted() {
    var tinderContainer = this.$refs.tinderContainer;
    var self = this;
    this.$refs.tinder.classList.add('loaded');
    Array.from(tinderContainer.children).forEach(function (el) {
      var hammertime = new Hammer(el);

      hammertime.on('pan', function () {
        el.classList.add('moving');
      });

      hammertime.on('pan', function (event) {
        if (event.deltaX === 0) return;
        if (event.center.x === 0 && event.center.y === 0) return;

        tinderContainer.classList.toggle('tinder_love', event.deltaX > 0);
        tinderContainer.classList.toggle('tinder_nope', event.deltaX < 0);

        var xMulti = event.deltaX * 0.03;
        var yMulti = event.deltaY / 400;
        var rotate = xMulti * yMulti;

        event.target.style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)';
      });

      hammertime.on('panend', function (event) {
        el.classList.remove('moving');
        tinderContainer.classList.remove('tinder_love');
        tinderContainer.classList.remove('tinder_nope');

        var moveOutWidth = document.body.clientWidth;
        var keep = Math.abs(event.deltaX) < 80 && Math.abs(event.velocityX) < 0.5;

        var curCard = self.cards.filter(card => card.id == event.target.id)[0];
        curCard.removed = !keep;
        if (keep) {
          event.target.style.transform = '';
        } else {
          console.log(event.deltaX);
          if(event.deltaX < 0){
            self.prefs.adventurous -= curCard.adventurous;
            self.prefs.beach -= curCard.beach;
            self.prefs.action -= curCard.action;
            self.prefs.city -= curCard.city;
            self.prefs.ancient -= curCard.ancient;
            self.prefs.hot -= curCard.hot;
          }else{
            self.prefs.adventurous += curCard.adventurous;
            self.prefs.beach += curCard.beach;
            self.prefs.action += curCard.action;
            self.prefs.city += curCard.city;
            self.prefs.ancient += curCard.ancient;
            self.prefs.hot += curCard.hot;
          }
          console.log(self.prefs);
          var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth * 2);
          var toX = event.deltaX > 0 ? endX : -endX;
          var endY = Math.abs(event.velocityY) * moveOutWidth;
          var toY = event.deltaY > 0 ? endY : -endY;
          var xMulti = event.deltaX * 0.03;
          var yMulti = event.deltaY / 80;
          var rotate = xMulti * yMulti;

          event.target.style.transform = 'translate(' + toX + 'px, ' + (toY + event.deltaY) + 'px) rotate(' + rotate + 'deg)';
          console.log("DISCARDING");
          self.curCard++;
          //initCards();
          var cardsRemaining = 0;
          for(var i = 0; i < self.cards.length; i++){
            if(!self.cards[i].removed){
              cardsRemaining++;
            }
          }
          if(cardsRemaining == 0){
            self.$emit("setPrefs", self.prefs);
            self.$emit("changeStep", "destination");
          }
        }
      });
    });
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

*, *:before, *:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body{
  overflow-y: hidden;
}

.tinder {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.loaded.tinder {
  opacity: 1;
}

.tinder--status {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  z-index: 2;
  width: 100%;
  text-align: center;
  pointer-events: none;
}

.tinder--status i {
  font-size: 100px;
  opacity: 0;
  transform: scale(0.3);
  transition: all 0.2s ease-in-out;
  position: absolute;
  width: 100px;
  margin-left: -50px;
}

.tinder__frame-overlay{
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 1000;
  background: transparent;
  border: 45px solid white;
  pointer-events: none;
}

.tinder_love .fa-heart {
  opacity: 0.7;
  transform: scale(1);
}

.tinder_nope .fa-remove {
  opacity: 0.7;
  transform: scale(1);
}

.tinder--cards {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  flex-grow: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
}

.tinder--card {
  display: inline-block;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: #FFFFFF;
  overflow: hidden;
  position: absolute;
  will-change: transform;
  transition: all 0.3s ease-in-out;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.moving.tinder--card {
  transition: none;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.tinder--card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  pointer-events: none;
}

.tinder--card h3 {
  margin-top: 32px;
  font-size: 32px;
  padding: 0 16px;
  pointer-events: none;
}

.tinder--card p {
  margin-top: 24px;
  font-size: 20px;
  padding: 0 16px;
  pointer-events: none;
}

.tinder--buttons {
  display: none;
  flex: 0 0 100px;
  text-align: center;
  padding-top: 20px;
}

.tinder--buttons button {
  border-radius: 50%;
  line-height: 60px;
  width: 60px;
  border: 0;
  background: #FFFFFF;
  display: inline-block;
  margin: 0 8px;
}

.tinder--buttons button:focus {
  outline: 0;
}

.tinder--buttons i {
  font-size: 32px;
  vertical-align: middle;
}

.fa-heart {
  color: #FFACE4;
}

.fa-remove {
  color: #CDD6DD;
}

.swipe__legal{
  position: absolute;
  z-index: 1000;
  font-family: "Swiss-721-Medium";
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  color: #aaaaaa;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  width: calc(100% - 300px);
}

.swipe__legal br{
  display: none;
}

.swipe__instructions{
  position: absolute;
  z-index: 1001;
  left: 45px;
  bottom: 16px;
}

.swipe__instructions-button{
  font-family: "Swiss-721-Medium";
  font-size: 18px;
  color: #b84c16;
  text-decoration: underline;
}

.tinder__counter-numerator{
  position: absolute;
  z-index: 2000;
  right: 155px;
  bottom: 128px;
  font-family: "Aldine-721-LightItalic";
  font-size: 122px;
  line-height: 134px;
  letter-spacing: 1px;
  text-align: right;
  color: white;
  text-shadow: 2px 4px rgba(0,0,0,.64);
  pointer-events: none;
}

.tinder__counter-denominator{
  position: absolute;
  z-index: 2000;
  right: 80px;
  bottom: 85px;
  font-family: "Aldine-721-LightItalic";
  font-size: 72px;
  line-height: 86px;
  letter-spacing: 1px;
  text-align: right;
  color: white;
  text-shadow: 2px 4px rgba(0,0,0,.64);
  pointer-events: none;
}

.swipe__instructions-modal-wrapper{
  position: fixed;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(0,0,0,.57);
}

.swipe__instructions-modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 70%;
  min-width: 600px;
  background-color: #1c1c1c;
  padding: 24px;
}

.swipe__instructions-modal-inner{
  border: 2px solid white;
  height: 100%;
  position: relative;
  padding-bottom: 196px;
}

.swipe__instructions-close{
  color: #FFFFFF;
  font-family: "Swiss-721-Medium";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  position: absolute;
  top: 18px;
  right: 18px;
}

.instructions__dislike{
  position: absolute;
  top: 350px;
  left: 0px;
  width: 300px;
  border-bottom: 1px solid white;
  color: white;
  transform: rotateZ(-90deg);
}

.instructions__like{
  position: absolute;
  top: 350px;
  right: 0px;
  width: 300px;
  border-bottom: 1px solid white;
  color: white;
  transform: rotateZ(90deg);
}

.instructions__dislike p, .instructions__like p {
  font-family: "Swiss-721-BoldCondensed";
  font-size: 18px;
  padding-bottom: 9px;
  line-height: 21px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.instructions__dislike-img{
  position: absolute;
  top: 100px;
  left: 130px;
}

.instructions__like-img{
  position: absolute;
  top: 100px;
  right: 130px;
}

.instructions__h2{
  color: #FFFFFF;
  font-family: "Swiss-721-BoldCondensed";
  font-size: 29px;
  letter-spacing: 2px;
  line-height: 35px;
  text-align: center;
  margin-top: 118px;
  text-transform: uppercase;
}

.instructions__p{
  color: #FFFFFF;
  font-family: "Swiss-721-Regular";
  font-size: 16px;
  letter-spacing: 0.22px;
  line-height: 27px;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 53px;
}

.mobile-only{
  display: none;
}

.desktop-only{
  display: block;
}

@media(max-width: 1100px){
  .instructions__like, .instructions__like-img, .instructions__dislike, .instructions__dislike-img{
    display: none;
  }

  .desktop-only{
    display: none;
  }

  .mobile-only{
    display: block;
  }
}

@media(max-width: 890px){
  .swipe__legal{
    right: 45px;
    left: auto;
    transform: none;
    text-align: right;
    width: calc(100% - 190px);
  }
}

@media (max-width: 780px){
  .tinder__counter-numerator{
    font-size: 74px;
    line-height: 80px;
    bottom: 80px;
    right: 120px;
  }

  .tinder__counter-denominator{
    font-size: 43px;
    line-height: 52px;
    bottom: 57px;
    right: 57px;
  }

  .swipe__legal{
    font-size: 10px;
    bottom: 5px;
  }

  .swipe__legal br{
    display: block;
  }
}
@media(max-height: 500px){
  .tinder__counter-numerator{
  font-size: 74px;
  line-height: 80px;
  bottom: 80px;
  right: 120px;
}

  .tinder__counter-denominator{
    font-size: 43px;
    line-height: 52px;
    bottom: 57px;
    right: 57px;
  }

}
@media (max-width: 640px){
  .swipe__instructions-modal{
    width: 95%;
    min-width: 0px;
  }
}

@media (max-width: 480px){
  .swipe__legal{
    right: 0px;
    bottom: 0px;
    transform-origin: bottom right;
    transform: rotateZ(-90deg) translateX(100%);
    width: 90vh;
    width: calc(var(--vh, 1vh) * 100);
    text-align: left;
    padding-left: 45px;
    padding-bottom: 15px;
  }

  .swipe__legal br{
    display: none;
  }
  
}
</style>
