<template>
  <footer>
    <p>© 1996-2023 Marriott International, Inc. All rights reserved. Marriott Proprietary Information.</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer{
  width: 100%;
}

footer p{
  font-family: "Swiss-721-Medium";
  margin-top: 3vh;
  margin-top: calc(var(--vh, 1vh) * 3);
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: #282828;
}
</style>
