<template>
  <div class="preview__wrapper">
    <div class="preview__frame--external mx-auto">
      <div class="preview__frame--internal">
        <img class="preview__image" :src="'/destinationHotels/' + destination.city.toLowerCase().replace(' ','-') + '.jpg'">
        <div v-if="isKiosk" class="qr-div">
          <img src="../assets/img/qr.png">
          <h3 class="qr__h3">Keep Exploring</h3>
        </div>
        <h1 class="preview__h1" :class="{'hotel-l' : destination.hotel.length > 15}">
          <img class="filter--black preview__h1-pin" src="../assets/img/Location.svg" />
          <span>&#8197;</span><span>{{destination.hotelLine1}}</span> <span>{{destination.hotelLine2}}</span>
          <hr/>
        </h1>
        <h2 class="preview__h2">{{destination.country ? destination.displayCity + ', ' + destination.country : destination.displayCity}}</h2>
        <p class="preview__p">Ready to go?<br>We know just where to stay.</p>
        <button v-if="!isKiosk" class="button--black" @click="goToMarriottSite">Keep Exploring</button>
        <button v-if="isKiosk" class="button--black" @click="$emit('changeStep', 'splash')">Done</button>
        <img class="preview__brand" src="../assets/img/brand-box.png">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'MapPreview',
  emits: ["changeStep"],
  props: {
    firstName: String,
    destination: Object,
    isKiosk: Boolean
  },
  mounted(){
    axios.post("/api/submitUser/", {firstName: this.firstName, destination: this.destination.city})
    .then(response => console.log(response));
  },
  methods: {
    goToMarriottSite: function(){
      window.location.href='https://www.marriott.com/explore/default.mi';
    }
  }
}
</script>

<style scoped>
.qr-div{
  position: absolute;
  background-color: white;
  top: 26px;
  right: 19px;
  width: 211px;
  height: 259px;
}

.qr__h3{
  color: #010101;
  font-family: "Aldine-721-LightItalic";
  font-size: 24px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.33px;
  line-height: 29px;
  margin-top: 2px;
}
.preview__wrapper{
  padding-top: 70px;
}
.preview__frame--external{
  width: 90%;
  max-width: 890px;
  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 0 0 14px 0 rgba(0,0,0,0.14);
  padding-top: 49px;
  padding-left: 23px;
  padding-right: 23px;
  padding-bottom: 43px;
}

.preview__frame--internal{
  margin-top: 0;
  margin-left: 26px;
  margin-right: 26px;
  margin-bottom: 32px;
  padding: 0px;
  position: relative;
  overflow-x: hidden;
}

.preview__h1{
  position: relative;
  color: #1C1C1C;
  font-family: "Aldine-721-LightItalic";
  font-size: 48px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.67px;
  line-height: 57px;
  text-align: center;
  margin-top: 47px;
  margin-bottom: 0px;
  text-transform: uppercase;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.preview__h1-pin{
  height: 35px;
  margin-left: 5px;
  margin-right: -5px;
  margin-bottom: 12px;
}

.preview__h1 hr{
  display: block;
  position: absolute;
  top: 50%;
  background-color: black;
  height: 2px;
  width: 100%;
  margin: 0;
  left: -100%;
}

.preview__h1 hr::after{
    content: "";
    height: 12px;
    margin-top: -5px;
    width: 2px;
    background-color: black;
    display: block;
    position: absolute;
    right: 0;
    z-index: 1000;
}

.hotel-l{
  font-size: 28px;
}

.hotel-l .preview__h1-pin{
  height: 25px;
  margin-left: 5px;
  margin-right: -8px;
  margin-bottom: 5px;
}

.preview__h2{
  color: #FF9662;
  font-family: "Swiss-721-BoldCondensed";
  font-size: 24px;
  letter-spacing: 0.33px;
  line-height: 29px;
  margin-top: 16px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.preview__p{
  color: #010101;
  font-family: "Aldine-721-LightItalic";
  font-size: 32px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.44px;
  line-height: 39px;
  text-align: center;
  margin-top: 14px;
  margin-bottom: 30px;
}

.preview__image{
  width: 100%;
  max-height: 40vh;
  max-height: calc(var(--vh, 1vh) * 40);
  object-fit: cover;
  object-position: center bottom;
}

.button--black{
  min-width: 230px;
}

.preview__brand{
  padding-top: 30px;
  width: 100%;
}
@media (max-width: 980px){
  .hotel-l {
    font-size: 18px;
    line-height: 20px;
  }

  .hotel-l .preview__h1-pin{
    height: 20px;
    margin-left: 5px;
    margin-right: -5px;
    margin-bottom: 0px;
    margin-top: -5px;
  }
}

@media(max-width: 540px){
  .qr-div{
    display: none;
  }
}

@media(max-width: 680px){
  .preview__h1:not(.hotel-l){
    font-size: 36px;
  }

  .preview__h1:not(.hotel-l) .preview__h1-pin{
    height: 28px;
    margin-left: 5px;
    margin-right: -5px;
    margin-bottom: 0px;
    margin-top: -10px;
  }

  .preview__p{
    font-size: 24px;
    line-height: 28px;
  }

  .preview__frame--external{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 29px;
  }

  .preview__frame--internal{
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }

  .preview__h1{
    margin-top: 24px;
  }
}
@media(max-width: 480px){
  .preview__h1:not(.hotel-l){
    font-size: 18px;
  }

  .preview__h1:not(.hotel-l) .preview__h1-pin{
    height: 28px;
    margin-left: 5px;
    margin-right: -5px;
    margin-bottom: 0px;
    margin-top: -10px;
  }
}

@media(max-width: 300px){
  .button--black{
    min-width: 90%;
  }
}
</style>
