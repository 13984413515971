<template>
    <div class="crm__wrapper">
    <form class="crm__form" @submit="validate($event)">
        <img class="crm__header-img" src="../assets/img/bonvoy-logo.png" @click="$emit('changeStep','splash')">
        <img class="crm__header-img-accent" src="../assets/img/path-connector.png">
        <div class="crm__header-join">
            Not a member? <span class="terms-link" @click="$emit('changeStep', 'register')">Join now.</span>
        </div>
        <h1 class="cell small-8 small-offset-2 crm__h1">Welcome Back to Marriott Bonvoy<sup>®</sup></h1>
        <div class="mobile-join">
            Not a member? <span class="terms-link" @click="$emit('changeStep', 'register')">Join now.</span>
        </div>
        <div class="grid-x double-stack">
            <label class="crm__label" for="firstName">First Name</label>
            <label class="crm__label" for="lastName">Last Name</label>
        </div>
        <div class="grid-x double-stack">
            <input v-model="firstName" class="cell small-8 small-offset-2" type="text" name="firstName" id="firstName" autocomplete="off" />
            <input v-model="lastName" class="cell small-8 small-offset-2" type="text" name="lastName" id="lastName" autocomplete="off" />
        </div>
        <div class="grid-x double-stack-alt">
            <label class="crm__label" for="firstName">First Name</label>
        </div>
        <div class="grid-x double-stack-alt">
            <input v-model="firstName" class="cell small-8 small-offset-2" type="text" name="firstName" id="firstNameMobile" autocomplete="off" />
        </div>
        <div class="grid-x double-stack-alt">
            <label class="crm__label" for="lastName">Last Name</label>
        </div>
        <div class="grid-x double-stack-alt">
            <input v-model="lastName" class="cell small-8 small-offset-2" type="text" name="lastName" id="lastNameMobile" autocomplete="off" />
        </div>
        <div class="grid-x">
            <label class="crm__label" for="email">Email Address</label>
        </div>
        <div class="grid-x">
            <input v-model="email" class="cell small-8 small-offset-2" type="email" name="email" id="email" required autocomplete="off" />
        </div>
        <div class="grid-x">
            <label class="crm__label" for="email">Marriott Bonvoy Member Number</label>
        </div>
        <div class="grid-x">
            <input v-model="accountNumber" class="cell small-8 small-offset-2" type="text" name="accountNumber" id="accountNumber" required autocomplete="off" />
        </div>
        <div class="grid-x crm__checkbox-wrapper crm__checkbox-wrapper--first">
          <label class="control control-checkbox">
              I agree to Marriott Bonvoy's Destination Matchmaker <span class="terms-link" @click="showTermsModal = true">Terms and Conditions</span>.
                  <input type="checkbox" v-model="matchmakerLegalAgree" id="matchmakerLegalAgree" name="matchmakerLegalAgree" autocomplete="off" />
              <div class="control_indicator"></div>
          </label>
        </div>
        <div class="grid-x crm__checkbox-wrapper">
          <label class="control control-checkbox">
              I agree to Marriott’s <span class="terms-link" @click="showGeneralTermsModal = true">Terms of Use</span> and Marriott Bonvoy <span class="terms-link" @click="showBonvoyTermsModal = true">Terms and Conditions</span>. I also acknowledge Marriott's <span class="terms-link" @click="showPrivacyPolicyModal = true">Privacy Statement</span> located in the Privacy Center.
                  <input type="checkbox" v-model="generalLegalAgree" id="generalLegalAgree" name="generalLegalAgree" autocomplete="off" />
              <div class="control_indicator"></div>
          </label>
        </div>
        <div class="grid-x">
            <button class="mx-auto button--black">Submit</button>
        </div>
        <div class="crm__error">{{error}}</div>
    </form>
    </div>
    <div v-if="showGeneralTermsModal" class="terms-modal--wrapper">
        <div class="terms-modal terms-modal--iframe">
            <iframe src="https://www.marriott.com/about/terms-of-use.mi"></iframe>
        </div>
        <button class="button--black" @click="showGeneralTermsModal = false">Close</button>
    </div>
    <div v-if="showBonvoyTermsModal" class="terms-modal--wrapper">
        <div class="terms-modal terms-modal--iframe">
            <iframe src="https://www.marriott.com/loyalty/terms/default.mi"></iframe>
        </div>
        <button class="button--black" @click="showBonvoyTermsModal = false">Close</button>
    </div>
    <div v-if="showPrivacyPolicyModal" class="terms-modal--wrapper">
        <div class="terms-modal terms-modal--iframe">
            <iframe src="https://www.marriott.com/about/privacy.mi"></iframe>
        </div>
        <button class="button--black" @click="showPrivacyPolicyModal = false">Close</button>
    </div>
    <div v-if="showTermsModal" class="terms-modal--wrapper">
    <div class="terms-modal">
        <h3><b>Terms &amp; Conditions - Marriott Bonvoy® Destination Matchmaker Game</b></h3>

        <p><b>Privacy</b>: Information collected from entrants is subject to the Sponsor’s Privacy Policies, which can be found at http://www.marriott.com/marriott/privacy-us.mi. Entrants should be aware that Sponsor may be collecting non-public, personally identifiable information about them in connection with the operation of the game. Subject to applicable law, Sponsor and Sponsor’s agents, affiliates, subsidiaries, representatives or service providers may use entrants’ personal information submitted with entry for future marketing by Sponsor, such as to notify them of a product or Competition that Sponsors think may be of interest. Sponsor will not sell personal information submitted with entry to outside solicitors.</p>
    </div>
    <button class="button--black" @click="showTermsModal = false">Close</button>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'CRM',
  emits: ["changeStep", "setName"],
  data() {
      return {
          firstName: null,
          lastName: null,
          email: null,
          accountNumber: null,
          error: null,
          matchmakerLegalAgree: false,
          generalLegalAgree: false,
          showTermsModal: false,
          showGeneralTermsModal: false,
          showBonvoyTermsModal: false,
          showPrivacyPolicyModal: false
      }
  },
  methods: {
      validate: function(e){
          e.preventDefault();
          console.log("validate attempt");
          if(this.firstName == null || this.firstName == ""){
            this.error = "First name is required.";
            return;
          }
          if(this.lastName == null || this.lastName == ""){
            this.error = "Last name is required.";
            return;
          }
          if(this.email == null || this.email == ""){
            this.error = "Email is required.";
            return;
          }
          if(!this.email.match(/^\S+@\S+\.\S+$/)){
             this.error = "Email must be valid.";
             return;
          }
          if(this.accountNumber == null || this.accountNumber == ""){
             this.error = "Account number is required.";
             return;
          }
          if(this.accountNumber.length < 8){
            this.error = "Account number must be at least 8 digits.";
            return;
          }
          if(this.matchmakerLegalAgree != true){
            this.error = "You must agree to Destination Matchmaker terms and conditions.";
            return;
          }

          if(this.generalLegalAgree != true){
            this.error = "You must agree to Marriott Bonvoy terms and conditions.";
            return;
          }
        this.error = "";
        axios.post("/api/submitUserToMarriott/", {first_name: this.firstName, last_name: this.lastName, email: this.email, member_number: this.accountNumber, is_terms_accepted: this.matchmakerLegalAgree && this.generalLegalAgree})
        .then(response => {
            console.log(response);
            this.$emit('setName', this.firstName);
            this.$emit('changeStep', 'instructions');
        });
      }
  }
}
</script>

<style scoped>
.terms-link{
    color: #B84c16;
    text-decoration: underline;
}

.terms-modal--wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.4);
}

.terms-modal--wrapper button{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
}

.terms-modal iframe{
    width: 100%;
    height: 100%;
}
.terms-modal{
    position: absolute;
    width: 90%;
    height: calc(95% - 100px);
    top: 5%;
    left: 5%;
    background-color: white;
    border: 2px solid black;
    overflow-y: auto;
    text-align: left;
}

.terms-modal--iframe{
    overflow-y: hidden;
}

.double-stack{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    width: 76%;
    margin: auto;
}

.double-stack-alt{
    display: none;
}

.crm__form .double-stack input, .crm__form .double-stack label{
    width: 100%;
}
.crm__error{
    margin-top: 20px;
    color: red;
    font-size: 20px;
}

.crm__wrapper{
  padding-top: 54px;
}
.crm__form{
  padding-top: 115px;
  width: 54%;
  margin-left: auto;
  margin-right: 44px;
  background: white;
  box-shadow: 0 0 14px 0 rgba(0,0,0,0.14);
  min-width: 730px;
  border-radius: 30px;
}

.crm__label, .crm__form input{
  width: 76%;
  margin: auto;
  text-align: left;
  color: #000000;
  font-family: "Swiss-721-Regular";
  font-size: 16px;
  letter-spacing: 0.22px;
  line-height: 19px;
}

.crm__label{
  margin-top: 23px;
  margin-bottom: 11px;
}

.crm__h1{
  width: 76%;
  margin: auto;
  color: black;
  font-family: "Swiss-721-BoldCondensed";
  font-size: 30px;
  letter-spacing: 1.42px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 43px;
}

.crm__form .button--black{
  margin-top: 70px;
  margin-bottom: 10px;
  min-width: 160px;
}

.crm__error{
    padding-bottom: 90px;
}

.crm__checkbox-wrapper{
  width: 76%;
  margin: auto;
  text-align: left;
  user-select: none; 
  color: #000000;
  font-family: "Swiss-721-Regular";
  font-size: 13px;
  letter-spacing: 0.18px;
}

.crm__checkbox-wrapper--first{
  margin-top: 47px;
  margin-bottom: 19px;
}

.crm__checkbox-wrapper .control{
  line-height: 16px;
  overflow: visable;
}

.crm__header-img{
    position: absolute;
    left: 11%;
    top: 38%;
    width: 20%;
    max-width: 276px;
}

.crm__header-img-accent{
    pointer-events: none;
    position: absolute;
    width: 40%;
    max-width: 554px;
    top: 43%;
    left: 11%;
    transform: translateX(-75%);
}

.crm__header-join{
    position: absolute;
    left: 11%;
    top: 70%;
    width: 20%;
    max-width: 276px;

    color: #000000;
    font-family: "Swiss-721-Regular";
    font-size: 16px;
    letter-spacing: 0.22px;
    line-height: 19px;
    text-align: center;
}

.mobile-join{
    color: #000000;
    font-family: "Swiss-721-Regular";
    font-size: 16px;
    letter-spacing: 0.22px;
    line-height: 19px;
    text-align: center;
    display: none;
}

@media (max-width: 1470px){
    .crm__form{
        padding-top: 75px;
    }
}

@media (max-width: 1170px){
    .mobile-join{
        display: block;
    }

    .crm__header-join{
        display: none;
    }

    .crm__header-img{
        position: static;
        width: 250px;
    }

    .crm__header-img-accent{
        display: none;
    }

    .crm__form{
        padding-top: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    .crm__h1{
        margin-top: 25px;
        margin-bottom: 10px;
    }

    .crm__form .button--black{
        margin-top: 35px;
        margin-bottom: 10px;
    }

    .crm__error{
        padding-bottom: 40px;
    }
}

@media (max-width: 745px){
    .crm__form{
        width: calc(100% - 40px);
        min-width: 0px;
    }

    .crm__h1{
        font-size: 18px;
    }

    .crm__header-img{
        width: 200px;
    }
    
    .crm__wrapper{
        padding-top: 20px;
    }

    .double-stack{
        display: none;
    }

    .double-stack-alt{
        display: block;
    }
}

@media (max-width: 500px){
    .crm__label, .crm__form input, .crm__h1, .crm__checkbox-wrapper{
        width: 90%;
    }

    .crm__checkbox-wrapper .control{
        font-size: 12px;
        line-height: 12px;
    }
}

@media(max-width: 430px){
    .crm__h1{
        font-size: 14px;
        margin-bottom: 0px;
    }
}

/* custom checkbox */
.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
}
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        width: 0;
    }
.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: none;
    border: 2px solid #707070;
    border-radius: 0px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: none;
}

.control input:checked ~ .control_indicator {
    background: none;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: none;
}
.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-checkbox .control_indicator:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 9px;
    border: solid #ff9662;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
}

</style>